<template>
  <div id="inspire">
    <div>
      <div
        fluid
        class="
          backgroundWindow
          flex
          justify-center
          items-center
          p-0
          h-screen
          px-4
        "
      >
        <div
          class="
            bg-white
            card
            rounded
            card_design
            w-full
            px-6
            sm:px-12
            py-4
            sm:m-0
          "
        >
          <div class="text-center">
            <p>Payment Success</p>
            <p>{{ pId }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pId: "",
    };
  },
  mounted() {
    this.pId = this.$route.query.subscribeId;
    console.log("this.$route.query", this.$route.query.subscribeId);
  },
  methods: {},
};
</script>